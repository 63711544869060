import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input, OnDestroy, DoCheck } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, AbstractControl, ValidationErrors,UntypedFormBuilder } from '@angular/forms';
import { Observable ,  Subscription } from 'rxjs';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { PbjOrganizationModel, PBJMigrationVersion } from '../../../models/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { LookupApiService } from '../../../../organization/services/index';
import { Company, State, EstablishmentType } from '../../../../organization/models/index';
import { TextEditorOptions } from '../../../../components-library/models/index';
import { ModalService } from '../../../../common/services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { IUser } from '../../../../authentication/store/index';
import { User } from '../../../../state-model/models/user/user';
import { CCNNumberValidatorAdapter } from '../../../services/index';
import { CommonValidators } from '../../../../common/validators/common-validators';
import { IServerValidationResult, IServerValidatorConf } from '../../../../common/validators/common-validators-models';
import { PbjOrganizationsConfigurationManagementService } from '../../../services/index';
import { OrgPayrollAppStatus, PayrollAppStatus } from '../../../models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { OrganizationTimezone } from '../../../../organization/models/lookup/organization-timezone';

function customNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
  
    if (value < 1 || value > 99) {
      return { 'invalidNumber': true };
    }
  
    return null;
  }
@Component({
    moduleId: module.id,
    selector: 'slx-pbj-organization-editor',
    templateUrl: 'pbj-organization-editor.component.html',
    styleUrls: ['pbj-organization-editor.component.scss']
})
export class PbjOrganizationEditorComponent extends EditableListEditorComponent implements OnInit, DoCheck, OnDestroy {

    @mutableSelect(['session', 'user'])
    public user$: Observable<IUser>;

    @Input()
    public set item(value: PbjOrganizationModel) {
        this.privateItem = value;
        this.createServerValidationConfig();
        if (this.formGroup) {
            this.updateFormGroup();
        }
    }

    @Input() 
    organizationTimezones:OrganizationTimezone[];


    public get item(): PbjOrganizationModel {
        return this.privateItem;
    }

    public get ccnValidation(): string {
        return CCNNumberValidatorAdapter.ccnNumberValidation;
    }

    public get originalCCN(): string {
        return this.privateItem.pbjOrganization.ccnNumber;
    }

    public get currentOrganizationId(): number {
        if (this.privateItem && this.privateItem.pbjOrganization) {
            return this.privateItem.pbjOrganization.organizationId;
        }
        return null;
    }

    public get conversionStateString(): string {
        if (this.item && this.item.pbjOrganization && this.item.pbjOrganization.conversionState) {
            return this.item.pbjOrganization.conversionState.state;
        }
        return 'Initial';
    }

    public get isApplicableForMigration(): boolean {
        if (this.privateItem) {
            if (this.privateItem.pbjOrganization && this.privateItem.pbjOrganization.migrationVersion) {
                let savedVersion: string = this.privateItem.pbjOrganization.migrationVersion.id;
                let currentVersion: string = this.migationVersionControl.value.id;
                return savedVersion !== currentVersion;
            }
        }
        return false;
    }

    public get isSystemUser(): boolean {
        return this.currentUser ? this.currentUser.username === 'system' : false;
    }

    @unsubscribe()
    public userSubscription: Subscription;
    @unsubscribe()
    public migrationVersionControlSubscription: Subscription;
    @unsubscribe()
    public conversionDateControlSubscription: Subscription;
    @unsubscribe()
    public payrollAppStatusControlSubscription: Subscription;
    @unsubscribe()
    public ccnNumberControlSubscription: Subscription;
    @unsubscribe()
    private mainFormSubscription: Subscription;

    public minConversionDate: Date;

    public appConfig: IApplicationConfig;
    public companiesLookup: Company[];
    public statesLookup: State[];
    public establishmentTypes: EstablishmentType[];
    public appVersions: PBJMigrationVersion[];
    public options: TextEditorOptions;
    public startOfYear: Date;
    public endOfYear: Date;
    public naicsCode: number = null;

    public migationVersionControl: AbstractControl;
    public conversionDateControl: AbstractControl;
    public ccnNumberControl: AbstractControl;

    private privateItem: PbjOrganizationModel;
    private tmplAppVersions: string[];
    private today: Date;
    private tomorrow: Date;
    private ignoreNextConversionPropertiesUpdate: boolean;
    private isConverted: boolean;
    private confirmation: any;

    private currentUser: User;
    public isSystemAdministrator :boolean=true;

    private serverValidatorConfig: IServerValidatorConf;

    private tempPayrollAppStatus: string[];
    public payrollAppStatus: OrgPayrollAppStatus[];
    public payrollAppStatusControl: AbstractControl;
    public payrollAppEffectiveDateControl: AbstractControl;
    private appSettingManageService: AppSettingsManageService;
    public nextgenPhasedRollout: boolean = false;
    public isNextgenPayrollEnabled: boolean = false;

    constructor(public ccnValidationAdapter: CCNNumberValidatorAdapter, private lookup: LookupApiService, private modal: ModalService, private management: PbjOrganizationsConfigurationManagementService,
        private fb: UntypedFormBuilder, appSettingManageService: AppSettingsManageService) {
        super();
        this.tmplAppVersions = ['SmartLinx 5', 'SmartLinx 6', 'Both'];
        this.tempPayrollAppStatus = [
          PayrollAppStatus.Off,
          PayrollAppStatus.Migration,
          PayrollAppStatus.Live,
          PayrollAppStatus.LiveWithTAExport
        ];
        this.appSettingManageService = appSettingManageService;
        this.appConfig = appConfig;
        this.options = new TextEditorOptions();
        this.options.toolbarButtons = [
            'fontFamily', 'fontSize', 'color', '|',
            'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', '|',
            'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
            'clearFormatting'
        ];
        this.options.placeholderText = 'Create your message here...';
        this.options.charCounterMax = 370;
        this.options.height = 200;

        const today: moment.Moment = moment();
        this.today = today.toDate();
        this.tomorrow = moment(today).add(1, 'day').toDate();
        this.startOfYear = today.startOf('year').toDate();
        this.endOfYear = today.endOf('year').toDate();
        this.createServerValidationConfig();
        // this.formGroup.controls['payrollCode'] = this.fb.group({
        //    payrollCode: ['', [Validators.required, customNumberValidator]],
        //   });
    }
    // rangeValidator(control) {
    //     const value = parseInt(control.value, 10);
    
    //     if (isNaN(value) || value < 1 || value > 99) {
    //       return { range: true };
    //     }
    
    //     return null;
    //   }
   
    //   onKeyPress(event: KeyboardEvent) {
    //     // Allow only numeric input and prevent other characters
    //     const charCode = event.which ? event.which : event.keyCode;
    //     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //       event.preventDefault();
    //     }
    //   }
    
    public ngOnInit(): void {
        this.userSubscription = this.user$.subscribe((user: User) => {
            this.currentUser = user;
            if(user.roles.includes("System Administrator"))
            {
                this.isSystemAdministrator=false;
            }
        });
        this.getSettings();
        this.initDefaults();
    }

    public ngOnDestroy(): void {
        // #issueWithAOTCompiler
        super.ngOnDestroy();
    }

    public ngDoCheck(): void {
        if (this.formGroup) {
            if ((this.item.pbjOrganization.naicsCode == null) && (this.naicsCode == null)) {
                this.formGroup.controls['naicsCode'].setErrors(null);
            }
        }
    }

    public onTextChange(html: string): void {
        this.formGroup.get('messageForUsers').setValue(html);
    }

    public onCodeChange(naicsCode) {
        this.naicsCode = naicsCode;
        if (_.isEmpty(naicsCode)) {
            this.formGroup.get('naicsCode').setErrors(null);
        } else {
            if (naicsCode.toString()[0] == 0) {
                this.formGroup.get('naicsCode').setErrors({ notLeadZeroes: true });
            }
        }
    }

    protected initDefaults(): void {
        let statePromise: Promise<State[]> = this.lookup.getStates();
        let companyPromise: Promise<Company[]> = this.lookup.getCompanies();
        let establishmentType: Promise<EstablishmentType[]> = this.lookup.getEstablishmentTypes();
        let appVersions: PBJMigrationVersion[] = this.makeAppVersions();
        let payrollAppStatus: OrgPayrollAppStatus[] = this.getPayrollAppStatus();
        Promise.all([statePromise, companyPromise, establishmentType]).then((results: any[]) => {
            this.statesLookup = results[0];
            this.companiesLookup = results[1];
            this.establishmentTypes = results[2];
            this.appVersions = appVersions;
            this.payrollAppStatus = payrollAppStatus;
            super.ngOnInit();
        });

    }

    protected createFormGroup(): UntypedFormGroup {

        let group: UntypedFormGroup = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            cmsPbjCode: new UntypedFormControl(''),
            company: new UntypedFormControl(''),
            address: new UntypedFormControl(''),
            city: new UntypedFormControl(''),
            state: new UntypedFormControl(''),
            zipCode: new UntypedFormControl(''),
            phone: new UntypedFormControl(''),
            companyCode: new UntypedFormControl(''),
            group: new UntypedFormControl(''),
            type: new UntypedFormControl(''),
            otherName: new UntypedFormControl(''),
            telepunchOffset: new UntypedFormControl(''),
            timezoneOffset: new UntypedFormControl(''),
            timezone: new UntypedFormControl(''),
            migrationVersion: new UntypedFormControl(''),
            conversionDate: new UntypedFormControl('', [Validators.required]),
            payrollCode: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]+$'),customNumberValidator]),
            payrollAppStatus: new UntypedFormControl(''),
            payrollAppEffectiveDate: new UntypedFormControl(''),
            messageForUsers: new UntypedFormControl(''),
            ccn: new UntypedFormControl(''),
            asOshaCompany: new UntypedFormControl(''),
            naicsCode: new UntypedFormControl(''),
            naicsDescription: new UntypedFormControl(''),
            establishmentType: new UntypedFormControl(''),
            establishmentSize: new UntypedFormControl(''),
            payGroup: new UntypedFormControl('')
        });

        this.migationVersionControl = group.get('migrationVersion');
        this.conversionDateControl = group.get('conversionDate');
        this.ccnNumberControl = group.get('ccn');

        this.payrollAppStatusControl = group.get('payrollAppStatus');
        this.payrollAppEffectiveDateControl = group.get('payrollAppEffectiveDate');

        this.createServerValidationConfig();
        this.ccnNumberControl.setAsyncValidators(CommonValidators.serverValidator(this.serverValidatorConfig));

        this.migrationVersionControlSubscription = this.migationVersionControl.valueChanges.subscribe((val: PBJMigrationVersion) => {
            let oldValue = this.item.pbjOrganization.migrationVersion;
            if (val.id === this.tmplAppVersions[0] && (oldValue.id === this.tmplAppVersions[1] || oldValue.id === this.tmplAppVersions[2])) {
                if (!this.confirmation) {
                    this.confirmation = this.modal.globalAnchor.openConfirmDialog('Confirm action',
                        'This will re-set all the migrated organizational data for Version 6, are you sure you want to proceed?', (result) => {
                            if (!result) {
                                this.migationVersionControl.setValue(oldValue);
                            }
                            this.updateMigrationState();
                            this.confirmation = null;
                        });
                }
            } else {
                this.updateMigrationState();
            }
        });

        this.conversionDateControlSubscription = this.conversionDateControl.valueChanges.subscribe(val => {
            this.updateConversionDateLimits();
        });


        this.ccnNumberControlSubscription = this.ccnNumberControl.valueChanges.subscribe(val => {
            const errors = Object.assign({}, this.ccnNumberControl.errors, { 'unverified': true });
            this.ccnNumberControl.setErrors(errors);
            this.ccnNumberControl.markAsPending();
            this.formGroup.updateValueAndValidity()
        });

        this.mainFormSubscription = group.statusChanges.subscribe(() => {
            if (this.formGroup.dirty) {
                this.management.markAsDirty();
            }
        });

        return group;
    }

    protected updateFormGroup(): void {
        let company: Company = _.find(this.companiesLookup, (c: Company) => {
            return c.id === this.item.pbjOrganization.company;
        });
        let state: State = _.find(this.statesLookup, (s: State) => {
            return s.name === this.item.pbjOrganization.state;
        });
        let establishmentType: EstablishmentType = _.find(this.establishmentTypes, (s: EstablishmentType) => {
            return s.id === Number(this.item.pbjOrganization.establishmentType);
        });
        let timezone: OrganizationTimezone = _.find(this.organizationTimezones, (t:OrganizationTimezone)=>{
            return t.timezone === this.item.pbjOrganization.timezone;
        })
        this.formGroup.get('company').setValue(company);
        this.formGroup.get('state').setValue(state);
        this.formGroup.get('name').setValue(this.item.pbjOrganization.name);
        this.formGroup.get('cmsPbjCode').setValue(this.item.pbjOrganization.cmsPbjCode);
        this.formGroup.get('address').setValue(this.item.pbjOrganization.address);
        this.formGroup.get('city').setValue(this.item.pbjOrganization.city);
        this.formGroup.get('zipCode').setValue(this.item.pbjOrganization.zip);
        this.formGroup.get('phone').setValue(this.item.pbjOrganization.phone);
        this.formGroup.get('companyCode').setValue(this.item.pbjOrganization.companyCode);
        this.formGroup.get('group').setValue(this.item.pbjOrganization.group);
        this.formGroup.get('type').setValue(this.item.pbjOrganization.type);
        this.formGroup.get('otherName').setValue(this.item.pbjOrganization.otherName);
        this.formGroup.get('migrationVersion').setValue(this.item.pbjOrganization.migrationVersion);
        this.formGroup.get('messageForUsers').setValue(this.item.pbjOrganization.messageForUsers);
        this.formGroup.get('telepunchOffset').setValue(this.item.telepunchOffset.fieldValue);
        this.formGroup.get('timezoneOffset').setValue(this.item.timezoneOffset.fieldValue);
        this.formGroup.get('timezone').setValue(timezone);
        this.formGroup.get('payrollCode').setValue(this.item.pbjOrganization.payrollCode);

        this.formGroup.get('payrollAppStatus').setValue(this.item.pbjOrganization.payrollAppStatus);
        this.formGroup.get('payrollAppEffectiveDate').setValue(this.item.pbjOrganization.payrollAppEffectiveDate);

        this.formGroup.get('ccn').setValue(this.item.pbjOrganization.ccnNumber);

        let savedConversionDate: Date = this.item.pbjOrganization.conversionDate || this.today;
        this.formGroup.get('conversionDate').setValue(savedConversionDate);
        this.isConverted = Math.abs(moment(savedConversionDate).diff(this.today, 'day')) > 1;

        this.formGroup.get('asOshaCompany').setValue(this.item.pbjOrganization.asOshaCompany);
        this.formGroup.get('naicsCode').setValue(this.item.pbjOrganization.naicsCode);
        this.formGroup.get('naicsDescription').setValue(this.item.pbjOrganization.naicsDescription);
        this.formGroup.get('establishmentType').setValue(establishmentType);
        this.formGroup.get('establishmentSize').setValue(this.item.pbjOrganization.establishmentSize);
        this.formGroup.get('payGroup').setValue(this.item.pbjOrganization.payGroup);

        this.updateMigrationState();
    }

    protected updateItem(): void {
        this.item.pbjOrganization.name = this.formGroup.get('name').value;
        this.item.pbjOrganization.cmsPbjCode = this.formGroup.get('cmsPbjCode').value;
        this.item.pbjOrganization.company = _.get(this.formGroup.get('company').value, 'id', null);
        this.item.pbjOrganization.companyName=_.get(this.formGroup.get('company').value, 'name', null);
        this.item.pbjOrganization.address = this.formGroup.get('address').value;
        this.item.pbjOrganization.city = this.formGroup.get('city').value;
        this.item.pbjOrganization.state = _.get(this.formGroup.get('state').value, 'name', null);
        this.item.pbjOrganization.zip = this.formGroup.get('zipCode').value;
        this.item.pbjOrganization.phone = this.formGroup.get('phone').value;
        this.item.pbjOrganization.companyCode = this.formGroup.get('companyCode').value;
        this.item.pbjOrganization.group = this.formGroup.get('group').value;
        this.item.pbjOrganization.type = this.formGroup.get('type').value;
        this.item.pbjOrganization.otherName = this.formGroup.get('otherName').value;
        this.item.pbjOrganization.ccnNumber = this.formGroup.get('ccn').value;
        this.item.pbjOrganization.payrollCode = this.formGroup.get('payrollCode').value;
        this.item.pbjOrganization.payrollAppStatus = this.formGroup.get('payrollAppStatus').value;
        this.item.pbjOrganization.payrollAppEffectiveDate = this.formGroup.get('payrollAppEffectiveDate').value;

        this.item.pbjOrganization.asOshaCompany = (this.formGroup.get('asOshaCompany').value);
        this.item.pbjOrganization.naicsCode = this.formGroup.get('naicsCode').value;
        this.item.pbjOrganization.naicsDescription = this.formGroup.get('naicsDescription').value;
        this.item.pbjOrganization.establishmentType = _.get(this.formGroup.get('establishmentType').value, 'id', null);
        this.item.pbjOrganization.establishmentSize = this.formGroup.get('establishmentSize').value;
        this.item.pbjOrganization.payGroup = this.formGroup.get('payGroup').value;
        let currentVersion: PBJMigrationVersion = this.formGroup.get('migrationVersion').value;
        if (currentVersion.id !== this.item.pbjOrganization.migrationVersion.id) {
            this.item.pbjOrganization.conversionDate = this.formGroup.get('conversionDate').value;
        } else {
            this.item.pbjOrganization.conversionDate = null;
        }
        this.item.pbjOrganization.migrationVersion = currentVersion;

        this.item.pbjOrganization.messageForUsers = this.formGroup.get('messageForUsers').value;
        this.item.pbjOrganization.telepunchOffset = this.formGroup.get('telepunchOffset').value;
        this.item.pbjOrganization.timezoneOffset = this.formGroup.get('timezoneOffset').value;
        this.item.pbjOrganization.timezone = _.get(this.formGroup.get('timezone').value, 'timezone', null);
        this.item.telepunchOffset.fieldValue = this.formGroup.get('telepunchOffset').value;
        this.item.timezoneOffset.fieldValue = this.formGroup.get('timezoneOffset').value;
    }

    private createServerValidationConfig() {
        this.serverValidatorConfig = { validationAdapter: this.ccnValidationAdapter, validationName: this.ccnValidation, parameters: [this.currentOrganizationId] };
    }

    private updateMigrationState(): void {

        if (this.isApplicableForMigration) {
            if (this.conversionDateControl.disabled) {
                this.conversionDateControl.enable();
            }
            this.updateConversionDateLimits();
        } else {
            if (this.conversionDateControl.enabled) {
                this.conversionDateControl.disable();
            }
        }
    }

    private updateConversionDateLimits(): void {

        if (this.ignoreNextConversionPropertiesUpdate) {
            this.ignoreNextConversionPropertiesUpdate = false;
            return;
        }
        this.ignoreNextConversionPropertiesUpdate = true;

        if (this.item.pbjOrganization.conversionDate) {
            if (this.isConverted) {
                this.minConversionDate = this.tomorrow;
            } else {
                if (this.conversionDateControl) {
                    if (!this.conversionDateControl.dirty) {
                        this.minConversionDate = moment(this.item.pbjOrganization.conversionDate).subtract(1, 'day').toDate();
                    } else {
                        this.minConversionDate = this.tomorrow;
                    }
                }
            }
        } else {
            this.minConversionDate = this.tomorrow;
        }
    }

    private makeAppVersions(): PBJMigrationVersion[] {
        return _.map(this.tmplAppVersions, (ver: string) => new PBJMigrationVersion(_.toLower(ver), ver));
    }

    private getPayrollAppStatus(): OrgPayrollAppStatus[] {
        return _.map(this.tempPayrollAppStatus, (ver: string) => new OrgPayrollAppStatus(this.getEnumKeyByEnumValue(PayrollAppStatus,ver), ver));    
    }

    private getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
        let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
        return keys.length > 0 ? keys[0] : '';
      }
        
    public async getSettings(): Promise<void> {
        let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
    }
}
