<slx-spinner [show]="isLoading">
  <div class="slx-high-box benefit-employees-eligible ">
    <div class="slx-content-toolbar-indents flex alighn-items__center justify-content__flex-end">
        <button type="button" class="theme-button-apply slx-button slx-blue" (click)="enrollEmployees()" [disabled]="selectedEmployees.size === 0 || canEnroll"
        title="{{ title }}">
          Enroll Employee(s)
        </button>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
        <form #form="ngForm" novalidate class="slx-full-height">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
            [data]="gridState?.view"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [sortable]="true"
            [sort]="gridState.state.sort"
            [pageSize]="pageSize"
            [pageable]="true"
            [skip]="gridState.state.skip"
            (dataStateChange)="gridState.dataStateChange($event)"
          >

            <kendo-grid-checkbox-column width="30" *ngIf="isUserCanEnroll">
              <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" class="k-checkbox checkbox-employee" [checked]="isCheckedAll" (change)="onSelectAll()">
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" class="k-checkbox checkbox-employee" [checked]="isCheckedEmp(dataItem)" (change)="onSelectSingle(dataItem)">
            </ng-template>
            </kendo-grid-checkbox-column>

            <kendo-grid-column title="Employee" width="80" field="employeeName" [sortable]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Employee</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                        {{dataItem.employeeName}}
                    </a>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Position" width="80" field="position.name" [sortable]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.position?.name}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Facility" width="80" field="organization.name" [sortable]="true" *slxHiddenOn="['tablet', 'mobile']">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Facility</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.organization?.name}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Department" width="80" field="department.name" [sortable]="true" *slxHiddenOn="['tablet', 'mobile']">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Department</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.department?.name}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Type" width="60" field="type.name" [sortable]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Type</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.type?.name}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *slxHiddenOn="['desktop']"
            title=""
            class="overflow-visible-cell action-column"
            [width]="30"
            [filterable]="false"
            [sortable]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="m-eligible">
                <span class="m-eligible__btn"
                  [popper]="eligibleInfo"
                  [popperTrigger]="'click'"
                  [popperPlacement]="'bottom-end'"
                  [popperDisableStyle]="'true'"
                >
                  <i class="fas fa-list-ul" aria-hidden="true"></i>
                </span>
                <popper-content #eligibleInfo class="slx-popper">
                  <section class="m-eligible__popper">
                    <div class="flex flex-direction-row m-eligible__group" *slxHiddenOn="['tablet']">
                      <p class="m-eligible__field">
                        <span class="m-eligible__title">Organization</span>
                        <span class="m-eligible__value">{{ dataItem.organization?.name }}</span>
                      </p>
                    </div>
                    <div class="flex flex-direction-row m-eligible__group" *slxHiddenOn="['tablet']">
                      <p class="m-eligible__field">
                        <span class="m-eligible__title">Department</span>
                        <span class="m-eligible__value">{{ dataItem.department?.name }}</span>
                      </p>
                    </div>
                  </section>
                </popper-content>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-messages filterMenuTitle="Filter">
          </kendo-grid-messages>  
          </kendo-grid>
        </form>
    </div>
</div>
</slx-spinner>
