import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { IServerValidatorConf } from '../../../../../common/validators/common-validators-models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import {
  BenefitDetailsConfig,
  BenefitDetailsGroup,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsProviderEntity,
  BenefitDetailsBasic,
  BenefitDetailsCalcMethod,
  BenefitDetailsEditModes
} from '../../../models/index';
import {
  BenefitDetailsManagementService,
  BenefitDetailsStandartManagementService,
  BenefitDetailsPermissionService,
  BenefitDetailsValidatorAdapter
} from '../../../services/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';

@Component({
  selector: 'slx-benefit-details-provider-info-line',
  templateUrl: './benefit-details-provider-info-line.component.html',
  styleUrls: ['./benefit-details-provider-info-line.component.scss'],
})
export class BenefitDetailsProviderInfoLineComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('provName', { static: true })
  public providerNameControl: NgModel;
  public config = new BenefitDetailsConfig();
  public group = new BenefitDetailsGroup();
  public providerName: string;
  public childAgeLimit: number;
  public isIncludeInReport: boolean;
  public costFreq: BenefitDetailsBasic<number, string>;
  public empFreq: BenefitDetailsBasic<number, string>;
  public calcMethod: BenefitDetailsCalcMethod;
  public effectiveStartDate: Date;
  public effectiveEndDate: Date;
  public deductionDate: Date;
  public mappedDeductionName: string;
  public showMappedDeduction: boolean;
  public min = 1;
  public max = 99;
  public step = 1;
  public isEditMode = false;
  public isDraftProvider = false;
  public isValidProviderName = true;
  public pattern = /[^\w\s]|[_]/gi;
  public maxValidDate: Date;
  public totalEnrolledEmployees: number = 0;
  public hasEnrolledEmployees: boolean = false;
  public hasEnrolledEmpTierLevel: boolean = false;
  public enrolledEmpTooltipText: string = "You cannot change the calculation method as employees are currently enrolled in this plan.";
  public orgLevelId: number;
  public serverValidationParams: IServerValidatorConf;
  public isBenefitDeductionEndabled:boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private providerLineStandart = new BenefitDetailsLineStandart();

  constructor(
    private modalService: ModalService,
    private commonManService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private manService: BenefitDetailsStandartManagementService,
    public benefitsValidatorAdapter: BenefitDetailsValidatorAdapter,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.maxValidDate = moment(appConfig.maxCorrectDate).toDate();
  }

  public ngOnInit() {
    this.subscriptions.orgLevel = this.commonManService
      .subscribeToOrgLevel((o: OrgLevel) => (this.orgLevelId = o.id));

    this.subscriptions.config = this.commonManService
      .subscribeToChangeConfig((config: BenefitDetailsConfig) => (this.config = config));

    this.subscriptions.group = this.commonManService
      .subscribeToSelectGroup((group: BenefitDetailsGroup) => (this.group = group));

    this.subscriptions.selectProvider = this.commonManService
      .subscribeToSelectProvider((p: BenefitDetailsProviderEntity) => {
        this.isDraftProvider = p.isDraft;
        this.hasEnrolledEmpTierLevel = false;
        this.assignValidationParams(!p.isDraft ? p.current.id : null);
      });

    this.subscriptions.providerLine = this.commonManService
      .subscribeToSelectProviderLine((v: BenefitDetailsProviderLineStandartEntity) => {
        const line = v.current;
        this.providerName = line.name || null;
        this.providerLineStandart = line;
        this.childAgeLimit = line.childAgeLimit;
        this.isIncludeInReport = line.includeInReport;
        this.costFreq = line.costFreq;
        this.empFreq = line.empContFreq;
        this.calcMethod = line.calcMethod;
        this.effectiveStartDate = line.line.startOfStartDate || null;
        this.effectiveEndDate = line.line.startOfEndDate || null;
        this.mappedDeductionName = line.mappedDeduction ? line.mappedDeduction.description : null;
        this.showMappedDeduction = this.mappedDeductionName && !line.mappedDeduction.isIgnored;
        this.deductionDate = line.payrollDeductionDate || null;
        this.hasEnrolledEmployees = _.gt(line.enrollmentCount, 0);
        this.getSettings();
      });

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));

    this.subscriptions.enrolledEmployeesInfo = this.manService.subscribeToBenefitEnrolledEmployeesInfo(
      (count: number) => {
        this.totalEnrolledEmployees = count || 0;
      }
    );

    this.subscriptions.enrolledEmployeesChanged = this.manService
      .subscribeToBenefitEnrolledEmployeesChanged((hasEnrollEmp: boolean) => {
        this.hasEnrolledEmpTierLevel = !this.isDraftProvider && hasEnrollEmp;
    });
  }

  public ngAfterViewInit(): void {
    this.subscriptions.enrolledEmployeesChanged = this.providerNameControl.statusChanges
      .subscribe((status: string) => {
        if (this.isEditMode) {
          this.manService.updateProviderName(this.providerNameControl.valid ? this.providerName : '');
        }
      });
  }

  public ngOnDestroy(): void {}

  public onChangeChildAgeLimit(): void {
    if (this.providerLineStandart.childAgeLimit !== this.childAgeLimit) {
      if (this.totalEnrolledEmployees > 0 && this.childAgeLimit <= this.max) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          'Warning',
          'There may be dependents enrolled that have exceeded max age!',
          this.modalService,
          (result: boolean) => { },
          options);
      }
      this.providerLineStandart.childAgeLimit = this.childAgeLimit;
      this.manService.updateProviderLine();
    }
  }

  public onChangeIncludeInReport(): void {
    if (this.providerLineStandart.includeInReport !== this.isIncludeInReport) {
      this.providerLineStandart.includeInReport = this.isIncludeInReport;
      this.manService.updateProviderLine();
    }
  }

  public onChangeCostFreq(): void {
    if (!_.isEqual(this.providerLineStandart.costFreq, this.costFreq)) {
      this.providerLineStandart.costFreq = this.costFreq;
      this.manService.updateProviderLine(true);
    }
  }

  public onChangeEmpFreq(): void {
    if (!_.isEqual(this.providerLineStandart.empContFreq, this.empFreq)) {
      this.providerLineStandart.empContFreq = this.empFreq;
      this.manService.updateProviderLine(false);
    }
  }

  public onChangeCalcMethod(): void {
    if (!_.isEqual(this.providerLineStandart.calcMethod, this.calcMethod)) {
      this.providerLineStandart.calcMethod = this.calcMethod;
      this.manService.updateProviderLine();
      this.manService.changeCalcMethod(this.calcMethod);
    }
  }

  public onChangeStartDate(): void {
    if (this.isEditMode && !moment(this.providerLineStandart.line.startDate).isSame(this.effectiveStartDate)) {
      this.providerLineStandart.line.startDate = this.effectiveStartDate;
      this.manService.updateProviderLine();
    }
  }

  public onChangeEndDate(): void {
    if (this.isEditMode && !moment(this.providerLineStandart.line.endDate).isSame(this.effectiveEndDate)) {
      this.providerLineStandart.line.endDate = this.effectiveEndDate;
      this.manService.updateProviderLine();
    }
  }

  public onChangeDeductionDate(): void {
    if (this.isEditMode && !moment(this.providerLineStandart.payrollDeductionDate).isSame(this.deductionDate)) {
      this.providerLineStandart.payrollDeductionDate = this.deductionDate;
      this.manService.updateProviderLine();
    }
  }

  public get isCalcMethodReadOnly(): boolean {
    return !this.isEditMode || this.hasEnrolledEmployees || this.hasEnrolledEmpTierLevel;
  }

  public get canHideCalcMethodToolTip(): boolean {
    return !(this.isEditMode && (this.hasEnrolledEmployees || this.hasEnrolledEmpTierLevel));
  }

  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isBenefitDeductionEndabled = config.isBenefitDeductionEnabled;
  }

  private assignValidationParams(providerId?: number): void {
    const params: number[] = [this.orgLevelId];
    if (_.isFinite(providerId)) {
      params.push(providerId);
    }
    this.serverValidationParams = {
      validationName: BenefitDetailsValidatorAdapter.providerNameValidation,
      parameters: params,
      validationAdapter: this.benefitsValidatorAdapter
    };
  }
}
