import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Subject ,  Subscription ,  Observable } from 'rxjs';

import { createValuAccessor } from '../../../common/utils/index';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  moduleId: module.id,
  selector: 'slx-dropdown-list',
  templateUrl: 'dropdown-list.component.html',
  styleUrls: ['dropdown-list.component.scss'],

  providers: [createValuAccessor(DropdownListComponent)]
})
export class DropdownListComponent implements ControlValueAccessor {
  @Input()
  public set options(items: any[]) {
    this.items = items;
  }

  @Input()
  public valueField: string;

  @Input()
  public valueIconClass: string ;

  @Input()
  public titleField: string;

  @Input()
  public valuePrimitive: boolean;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public defaultItem: any;

  @Input()
  public icon: string;

  @Input()
  public disableEmittingIfReadonly: boolean;

  @Input()
  public listHeight: number = 200;

  @Output()
  public open: EventEmitter<null>;

  @Output()
  public close: EventEmitter<null>;

  @Input()
  public virtual: VirtualizationSettings;

  @Input()
  public isInvalidType: boolean;

  @ViewChild('dropdownlist', {static: true})
  public dropdownlist: any;

  public items: any[];

  private m_selectedValue: any;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {
    this.valueField = 'id';
    this.titleField = 'name';

    this.items = [];
    this.disableEmittingIfReadonly = false;

    this.open = new EventEmitter<null>();
    this.close = new EventEmitter<null>();
  }

  public get selectedItem(): any {
    return this.m_selectedValue;
  }

  public get hasValueIcon(): boolean {
    return !_.isEmpty(this.valueIconClass);
  }
  public set selectedItem(value: any) {
    if (value !== this.m_selectedValue) {
      this.m_selectedValue = value;
      if (!this.disableEmittingIfReadonly || this.disableEmittingIfReadonly && !this.readonly) {
        this.onChangeCallback(this.m_selectedValue);
      }
    }
  }

  public onOpen(): void {
    this.onTouchedCallback();
    this.open.emit(null);
  }

  public onClose(): void {
    this.close.emit(null);
  }

  public onBlur(): void {
    this.onTouchedCallback();
  }

  public isPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  public writeValue(value: any): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.selectedItem = value;
    } else {
      this.selectedItem = this.defaultItem;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public toggleDropdown(): void {
    this.dropdownlist.toggle();
  }
}
