import { PopoverContentComponent } from './../../../../common/components/popover-content/popover-content.component';
import { OrgLevel } from '../../../../state-model/models/index';
import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import * as _ from 'lodash';
import {Position, PositionGroup } from './../../../../organization/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-open-shift-management-filters',
    templateUrl: 'open-shift-management-filters.component.html',
    styleUrls: ['open-shift-management-filters.component.scss']
  })
export class OpenShiftManagementFiltersComponent {
    @Output()
    public filtersChanged: EventEmitter<any>;
    @Output()
    public onShow: EventEmitter<boolean>;
    @Input()
    public selectedOrgLevel: OrgLevel;
    @ViewChild('filterPopover', {static: true})
    public filterPopover: PopoverContentComponent;

    public positionGroupsFilter : PositionGroup[];
    public posFilter: Position[];
    public unitFilter: any;
    public shiftFilter: any;
    public shiftGroupFilter: any;
    public filtersApplied: boolean;

    private selectedPositionGroupIds : number[];
    private selectedPositionIds: number[];
    private selectedShiftGroupIds: number[];
    private selectedUnitIds: number[];
    private selectedShiftIds: number[];
    private prePositionGroupFilter : PositionGroup[];
    private prevPosFilter: Position[];
    private prevUnitFilter: any;
    private prevShiftFilter: any;
    private prevShiftGroupFilter: any;

    constructor() {
        this.filtersChanged = new EventEmitter();
        this.onShow = new EventEmitter();
    }

    public onFilterChange(filterName: string, filter: any[]): void {
        switch (filterName) {
          case LookupType.locationUnit:
            this.selectedUnitIds = filter;
          break;
          case LookupType.position:
            this.selectedPositionIds = filter;
          break;
          case LookupType.shift:
            this.selectedShiftIds = filter;
          break;
          case LookupType.shiftGroupDefinition:
            this.selectedShiftGroupIds = filter;
          break;
          case LookupType.positionGroups :
              this.selectedPositionGroupIds = filter;
          break;
        }
    }

    public show(): void {
      this.onShow.emit(true);
      this.savePreviousFilters();
    }

    public savePreviousFilters(): void {
        this.prePositionGroupFilter = this.positionGroupsFilter ? this.positionGroupsFilter.concat([]) : [];
        this.prevPosFilter = this.posFilter ? this.posFilter.concat([]) : [];
        this.prevUnitFilter = this.unitFilter ? this.unitFilter.concat([]) : [];
        this.prevShiftFilter = this.shiftFilter ? this.shiftFilter.concat([]) : [];
        this.prevShiftGroupFilter = this.shiftGroupFilter ? this.shiftGroupFilter.concat([]) : [];
    }

    public applyFilters(): void {
        this.filtersChanged.emit(this.getFilters());
        this.calcAppliedFilters();
    }

    public restoreFilters(): void {
        this.positionGroupsFilter = this.prePositionGroupFilter;
        this.posFilter = this.prevPosFilter;
        this.unitFilter = this.prevUnitFilter;
        this.shiftFilter = this.prevShiftFilter;
        this.shiftGroupFilter = this.prevShiftGroupFilter;
    }

    public getFilters(): any {
        let filters: any = {};
        if (this.selectedPositionGroupIds && this.selectedPositionGroupIds.length) {
          filters.positionGroupIds = this.selectedPositionGroupIds;
        }
        if (this.selectedPositionIds && this.selectedPositionIds.length) {
          filters.positionIds = this.selectedPositionIds;
        }
        if (this.selectedShiftGroupIds && this.selectedShiftGroupIds.length) {
          filters.shiftGroupIds = this.selectedShiftGroupIds;
        }
        if (this.selectedUnitIds && this.selectedUnitIds.length) {
          filters.unitIds = this.selectedUnitIds;
        }
        if (this.selectedShiftIds && this.selectedShiftIds.length) {
          filters.shiftIds = this.selectedShiftIds;
        }
        return filters;
    }

    public calcAppliedFilters(): void {
        this.filtersApplied = Boolean(this.selectedUnitIds && this.selectedUnitIds.length) ||
          Boolean(this.selectedPositionIds && this.selectedPositionIds.length) ||
          Boolean(this.selectedShiftGroupIds && this.selectedShiftGroupIds.length) ||
          Boolean(this.selectedShiftIds && this.selectedShiftIds.length) || 
          Boolean(this.selectedPositionGroupIds && this.selectedPositionGroupIds.length);
    }

    public cancelAndClose(): void {
      this.filterPopover.hide();
      this.restoreFilters();
  }
}
