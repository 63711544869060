import { AppServerConfig } from './../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { ConfigurePositionsReassignPopupComponent } from './reassignPopup/configure-positions-reassign-popup.component';
import { ManagePositionGroupsDialogComponent } from './manage-groups/manage-position-groups-dialog/manage-position-groups-dialog.component';
import { PositionModel, RemovePositionRequest, AdditionalInfo } from '../../models/index';
import { PositionsManagementService, AccessManagementService } from '../../services/index';
import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { PositionsContainer } from '../../models/positions/positions-container';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { screenUtils } from '../../../common/utils/index';
import { GroupDescriptor } from '@progress/kendo-data-query';

import { ExcelExportEvent } from '@progress/kendo-angular-grid/excel/excel-export-event';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-positions',
  templateUrl: 'configure-positions.component.html',
  styleUrls: ['configure-positions.component.scss'],
  providers: [PositionsManagementService, AccessManagementService]
 })
export class ConfigurePositionsComponent implements OnInit, OnDestroy {
  isAddPosition: boolean = false;

  public get container(): PositionsContainer {
    return this.m_container;
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<PositionModel>;
  public gridState: KendoGridStateHelper<PositionModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<PositionModel>;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  public appConfig: IApplicationConfig;
  public essTemplatesEnabled: boolean;
  public groups: GroupDescriptor[] = [{field: 'positionGroupName'}];
  @destroyService()
  public management: PositionsManagementService;

  private m_container: PositionsContainer;

  @ViewChild('kendoGrid')
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }
  private get grid(): GridComponent {
    return this.m_grid;
  }
  private m_grid: GridComponent;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private onloadedSubscription: Subscription;

  @unsubscribe()
  private orgChangeSubscription: Subscription;

  public currentOrgLevel: OrgLevel;

  public exportedFileHeader: string;

  constructor(
    management: PositionsManagementService,
    private modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService
  ) {

    this.management = management;

    this.gridState = new KendoGridStateHelper<PositionModel>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<PositionModel>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      this.isAddPosition = true;
      let position: PositionModel = new PositionModel();
      position.additionalInfo = new AdditionalInfo();
      this.crudHelper.addItem(position);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {

    this.appConfig = appConfig;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.savedSubscription = this.management.onItemSaved$.subscribe((item: PositionModel) => {
      this.gridState.editedRecord = null;
      this.gridState.savedEditedRecord = null;
    });

    this.onloadedSubscription = this.management.onLoaded$.subscribe(state => {
      this.gridState.state.group = this.groups;
    });

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
      this.essTemplatesEnabled = this.management.essTemplatesEnabled;
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: PositionModel[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: RemovePositionRequest) => {
      if (request.requireReassign) {

        ConfigurePositionsReassignPopupComponent.openDialog(request, this.modalService, (result: boolean, reassingRequest: RemovePositionRequest) => {
          if (result) {
            this.management.doRemoveItem(request.itemToDelete, reassingRequest.ressignedPositionId, reassingRequest.effectiveDate);
          }
        });

      } else {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Confirmation',
          'Open Shifts are posted for this position, by deleting the position, all open shifts for this position will be removed effective of this date and this cannot be undone. Are you sure you want to delete this position?',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.management.doRemoveItem(request.itemToDelete);
            }
          }, options);
      }
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();

    this.orgChangeSubscription = this.management.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
    });
    this.getSettings();
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public onManageGroups(): void {
    ManagePositionGroupsDialogComponent.openDialog(this.modalService, (hasChanges: boolean) => {
      if (hasChanges) {
        this.management.loadPositions();
      }
    });
  }

  public onCopyItems(event: MouseEvent): void {
    event.preventDefault();
    this.management.openCopyItems();
  }

  public switchToConfigure(): void {
    this.management.closeCopyItems();
  }

  public extenalEditorAction(action: EditableListActionKind): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.isAddPosition = false;
      this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.isAddPosition = false;
      this.crudHelper.onCancelItem(this.management.editingItem);
    }
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: PositionModel, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    }
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      return {
        data: _.cloneDeep(this.gridState.view.data),
        group: this.gridState.state.group
      };
    };
  }

  public onExportToExcel(): void {
    this.generateExportFileHeader();
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.generateExportFileHeader();
    this.grid.saveAsPDF();
  }


  public generateExportFileHeader(): void {
    let organization: string = this.currentOrgLevel.name;
    let exported: string = moment().format(appConfig.dateTimeFormatUS);
    let header: string = `${organization} Exported on ${exported}`;
	
    if (this.currentOrgLevel.type === OrgLevelType.department) {
      header =_.last(this.currentOrgLevel.treeViewNamePath) + ' - ' + header;
    }
	
    this.exportedFileHeader = header;
  }
  
  public onExcelExport(event: ExcelExportEvent): void {
    event.workbook.sheets[0].rows.unshift({
      cells: [
        {
          value: this.exportedFileHeader
        }
      ]
    });
  }
  public appSetting: AppServerConfig 
  private async getSettings(): Promise<void> {
     
     this.appSetting = await this.appSettingsManageService.getAppServerConfig();
     
  }

 }
