
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable ,  ReplaySubject ,  Subject ,  Subscription } from 'rxjs';
import * as moment from 'moment';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ChangeManagementService } from '../../../common/index';
import {acaQuickPopupState,Aca1095c,aca1095CPopupAction,Aca1095CPopupInitData, EmployeeFilterRecord, EmployeeFilter, Aca1095cRecord, AcaMonthRecord, AcaInsightFilter, EmployeeIdList,AcaInsightSummaryRecords } from '../../models/aca-1095-c/aca-1095-c-record';
import { IAca1095cState, initialAca1095cState, Aca1095cFilterState } from '../../models/aca-1095-c/aca-1095-c-state';
import { Aca1095cApiService } from './aca-1095-c-api.service';
import { ColumnManagementService, ColumnsChangedEvent, StateManagementService } from '../../../common';
import { Aca1095cSettings, Aca1095cColumnsSettings } from '../../models/aca-1095-c/aca-1095-c-settings';
import { StateResetTypes } from '../../../core/models';
import { LookupApiService } from '../../../organization/services/index';
import { AcaOfferOfCoverage,AcaSafeHarbor,AcaReason} from '../../../organization/models/index';

@Injectable()
export class Aca1095cManagementService {

  private readonly stateKey: string = 'Aca1095cState';
  private originalContainer: Aca1095c;
  private orgLevel: OrgLevel;
  private selectedYear: number = moment().toDate().getFullYear();
  private insightsFilter: AcaInsightFilter;
  public  filter: EmployeeFilterRecord[] = [];
  public  expandedEmployeeList: EmployeeIdList[] = [];
  public  acaFilterState: Aca1095cFilterState = new Aca1095cFilterState();
  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<Aca1095c>();
  private insightsLoaded$=new Subject<AcaInsightSummaryRecords>();
  private employeeFilterRecords$ = new Subject<EmployeeFilterRecord[]>();
  private employeeFilter$ = new Subject<EmployeeFilter>();
  private exportTo$ = new Subject<boolean>();
  private expandAll$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private addRemoveEmployee$ = new Subject<Aca1095cRecord>();
  public  onSettingsChanged$: ReplaySubject<Aca1095cSettings>;
  private init1095Popup$=new Subject<Aca1095CPopupInitData>();
  private acaQuickPopupState$ = new Subject<string>();
  private quickEditInitData$ = new Subject<AcaMonthRecord>();
  private quickEditSelectedData$ = new Subject<AcaMonthRecord>();
  private quickEditRecords$ = new Subject<Aca1095cRecord[]>();
  private quickConfirmRecords$ = new Subject<Aca1095cRecord[]>();
  private quickConfirmResetRecords$ = new Subject<Aca1095cRecord[]>();
  private quickEditResetRecords$ = new Subject<Aca1095cRecord[]>();
  private undoQuickRecords$ = new Subject<Aca1095cRecord>();
  private quickMonthAction$ = new Subject<any>();
  private showWarningPopup$ = new Subject<string>();
  public  state: IAca1095cState;
  public  acaReason: AcaReason[];
  public  acaOfferOfCoverage: AcaOfferOfCoverage[];
  public  acaSafeHarbor: AcaSafeHarbor[];
  public  quickpopupState: string;
  public  isExpandAll: boolean;
  @unsubscribeAll('destroy')
  public subscriptions: StringMap<Subscription> = {};

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  constructor(
    private apiService: Aca1095cApiService,
    private columnManagement: ColumnManagementService,
    private stateManagement: StateManagementService,
    private lookupApiService:LookupApiService,
    private changeService: ChangeManagementService 
  ) {
    this.onSettingsChanged$ = new ReplaySubject(1);
  }

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.columnSettingsChanged();
    this.getAcaSafeHarbor();
    this.getAcaReason();
    this.getAcaOfferOfCoverage();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.insightsLoaded$.complete();
    this.employeeFilterRecords$.complete();
    this.exportTo$.complete();
    this.expandAll$.complete();
    this.orgLevelChanged$.complete();
    this.employeeFilter$.complete();
    this.addRemoveEmployee$.complete();
    this.init1095Popup$.complete();   
    this.quickEditInitData$.complete();
    this.quickEditSelectedData$.complete();

    this.quickEditRecords$.complete();
    this.quickConfirmRecords$.complete();
    this.quickConfirmResetRecords$.complete();
    this.quickEditResetRecords$.complete();
    this.quickMonthAction$.complete();
    this.showWarningPopup$.complete();
    this.undoQuickRecords$.complete();
  }
  public setUndoQuickRecord(item:Aca1095cRecord)
  {
    this.undoQuickRecords$.next(item);
  }
 
  public markAsDirty(): void {
    this.changeService.changeNotify();
  }
  public clearChanges(): void {
    this.changeService.clearChanges();

  }
  public setShowPopupMessage(message:string):void{
    this.showWarningPopup$.next(message);
  }
  public setQuickMonthAction(monthAction:any):void  {
    this.quickMonthAction$.next(monthAction);      
  }
  public setQuickPopupState(state:string):void  {
         this.quickpopupState=state;
         this.acaQuickPopupState$.next(state);      
  }
  public subscribeToUndoQuickRecord(callback: (item:Aca1095cRecord) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.undoQuickRecords$.subscribe(callback);
 }
  public subscribeToShowPopupMessage(callback: (b: string) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.showWarningPopup$.subscribe(callback);
 }
  public subscribeToQuickMonthAction(callback: (b: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.quickMonthAction$.subscribe(callback);
 }
  public subscribeToQuickPopupState(callback: (b: string) => void): Subscription {
     Assert.isNotNull(callback, 'callback');
     return this.acaQuickPopupState$.subscribe(callback);
  }

  public submitQuick1095cRecords(records:Aca1095cRecord[],popupSate:string)
  {
    switch(popupSate) { 
      case acaQuickPopupState.quickConfirmSave: 
      { 
        this.saveQuickConfirm1095cRecords(records);
        break; 
      } 
      case acaQuickPopupState.quickEditSave: 
      { 
        this.saveQuickEdit1095cRecords(records);
         //statements; 
         break; 
      } 
      case acaQuickPopupState.quickEditResetSave: 
      { 
        this.saveQuickEditReset1095cRecords(records);
         //statements; 
         break; 
      } 
      case acaQuickPopupState.quickConfirmResetSave: 
      { 
         this.saveQuickConfirmReset1095cRecords(records);
         break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   }   
    
  }

  public setQuickEditRecords(data:Aca1095cRecord[]):void  {
    this.quickEditRecords$.next(data);
  }
  public setQuickConfirmRecords(data:Aca1095cRecord[]):void  {
    this.quickConfirmRecords$.next(data);
  }
  public setQuickConfirmResetRecords(data:Aca1095cRecord[]):void  {
    this.quickConfirmResetRecords$.next(data);
  }
  public setQuickEditResetRecords(data:Aca1095cRecord[]):void  {
    this.quickEditResetRecords$.next(data);
  }
  
  public setQuickEditInitData(editData:AcaMonthRecord):void  {
    this.quickEditInitData$.next(editData);
  }
  public setQuickEditSelectedData(editData:AcaMonthRecord):void  {
    this.quickEditSelectedData$.next(editData);
  }
  

  public isAddRemoveEmployee(aca1095cRecord:Aca1095cRecord):void{
    this.addRemoveEmployee$.next(aca1095cRecord);
  }
  public init1095Popup(data:Aca1095CPopupInitData):void{
    this.init1095Popup$.next(data);
  }
  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }
  public expandAll(isExpand: boolean): void {
    this.expandAll$.next(isExpand);
  }
  public settingsChanged(columnsSettings: Aca1095cSettings): void {
    this.onSettingsChanged$.next(columnsSettings);
  }
  public changeEmployeeFilter(filters: EmployeeFilterRecord[]): void {
    this.employeeFilterRecords$.next(filters);
  }
  public setEmployeeFilterRecords(empFilter: EmployeeFilter): void {
    this.employeeFilter$.next(empFilter);
  }
  public subscribeToQuickEditRecords(callback: (b: Aca1095cRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.quickEditRecords$.subscribe(callback);
  }
  public subscribeToQuickConfirmRecords(callback: (b: Aca1095cRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.quickConfirmRecords$.subscribe(callback);
  }
  public subscribeToQuickEditResetRecords(callback: (b: Aca1095cRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.quickEditResetRecords$.subscribe(callback);
  }
  public subscribeToQuickConfirmResetRecords(callback: (b: Aca1095cRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.quickConfirmResetRecords$.subscribe(callback);
  }
  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }
  public subscribeToIsAddRemoveEmployee(callback:(e:Aca1095cRecord)=>void): Subscription{
    Assert.isNotNull(callback, 'callback');
    return this.addRemoveEmployee$.subscribe(callback);
  }
  public subscribeToInit1095Popup(callback:(e:Aca1095CPopupInitData)=>void): Subscription{
    Assert.isNotNull(callback, 'callback');
    return this.init1095Popup$.subscribe(callback);
  }
  public subscribeToQuickEditInitData(callback:(e:AcaMonthRecord)=>void): Subscription{
    Assert.isNotNull(callback, 'callback');
    return this.quickEditInitData$.subscribe(callback);
  }
  public subscribeToQuickEditSelectedData(callback:(e:AcaMonthRecord)=>void): Subscription{
    Assert.isNotNull(callback, 'callback');
    return this.quickEditSelectedData$.subscribe(callback);
  }
  
  
  public subscribeToExpandAll(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.expandAll$.subscribe(callback);
  }
  public subscribeToLoadedRecords(callback: (b: Aca1095c) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }
  public subscribeToInsightsRecords(callback: (b: AcaInsightSummaryRecords) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.insightsLoaded$.subscribe(callback);
  }
  public subscribeToEmpFilterRecords(callback: (b: EmployeeFilterRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.employeeFilterRecords$.subscribe(callback);
  }
  public subscribeToEmployeeFilter(callback: (f: EmployeeFilter) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.employeeFilter$.subscribe(callback);
  }
  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }
  public removeEmployee(empId:number,empMasterId: number,companyId:number): void {
   
    this.loading$.next(true);
    this.apiService.removeEmployee1095c(this.orgLevel.id, this.selectedYear, empMasterId,empId,companyId)
      .then((container: Aca1095c) => {
        this.setAca1095cRecords(container,this.acaFilterState,true);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }

  public saveQuickEdit1095cRecords(requestBody:any): void {
   
    this.loading$.next(true);
    this.apiService.edit1095cRecords(this.orgLevel.id,this.selectedYear,requestBody)
      .then((container: Aca1095c) => {
        this.quickEditRecords$.next();
        this.setQuickPopupState(acaQuickPopupState.quickEditClose);        
        this.setAca1095cRecords(container,this.acaFilterState,true);
        
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }
  public addEmployee(empId:number,empMasterId: number,companyId:number,departmentId:number,organizationId:number): void {
   
    this.loading$.next(true);
    this.apiService.addEmployee1095c(this.orgLevel.id, this.selectedYear, empMasterId,empId,companyId,departmentId,organizationId)
      .then((container: Aca1095c) => {
        this.setQuickPopupState(acaQuickPopupState.quickEditClose);
        this.setAca1095cRecords(container,this.acaFilterState,true);
        
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }

  public saveQuickConfirm1095cRecords(requestBody:any): void {
   
    this.loading$.next(true);
    this.apiService.confirm1095cRecords(this.orgLevel.id,this.selectedYear,requestBody)
      .then((container: Aca1095c) => {
        this.quickEditRecords$.next();
        this.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
        this.setAca1095cRecords(container,this.acaFilterState,true);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }
  public saveQuickEditReset1095cRecords(requestBody:any): void {
   
    this.loading$.next(true);
    this.apiService.editReset1095cRecords(this.orgLevel.id,this.selectedYear,requestBody)
      .then((container: Aca1095c) => {
        this.quickEditRecords$.next();
        this.setQuickPopupState(acaQuickPopupState.quickEditClose);
        this.setAca1095cRecords(container,this.acaFilterState,true);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }
  public saveQuickConfirmReset1095cRecords(requestBody:any): void {
   
    this.loading$.next(true);
    this.apiService.confirmReset1095cRecords(this.orgLevel.id,this.selectedYear,requestBody)
      .then((container: Aca1095c) => {
        this.quickEditRecords$.next();
        this.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
        this.setAca1095cRecords(container,this.acaFilterState,true);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }
  public resetQuickPopupState(state:string):void
  {
    switch(state)
    {
      case acaQuickPopupState.quickEditActive:
      case acaQuickPopupState.quickEditStart:
      {
        this.setQuickPopupState(acaQuickPopupState.quickEditClearAndClose)
      }
      case acaQuickPopupState.quickConfirmActive:
      case acaQuickPopupState.quickConfirmStart:
      {
        this.setQuickPopupState(acaQuickPopupState.quickConfirmClearAndClose)
      }
      case acaQuickPopupState.quickEditResetActive:
      case acaQuickPopupState.quickEditResetStart:
      {
        this.setQuickPopupState(acaQuickPopupState.quickEditResetClearAndClose)
      }
      case acaQuickPopupState.quickConfirmResetActive:
      case acaQuickPopupState.quickConfirmResetStart:
      {
        this.setQuickPopupState(acaQuickPopupState.quickConfirmResetClearAndClose)
      }
    }
  }
  public getAcaSafeHarbor(): void {
    this.lookupApiService.getAcaSafeHarbors()
    .then((data: AcaSafeHarbor[]) => {
      this.acaSafeHarbor=data;
    })
          
  }
  public getAcaReason(): void {
    this.lookupApiService.getAcaReasons()
    .then((data: AcaReason[]) => {
      this.acaReason=data;
    })
  }
  public getAcaOfferOfCoverage(): void {
    this.lookupApiService.getAcaOfferOfCoverage(this.selectedYear)
    .then((data: AcaOfferOfCoverage[]) => {
      this.acaOfferOfCoverage=data;
    })
  }
  public getSelectedYear():number{
    return this.selectedYear;
  }
  public setAca1095cRecords(container: Aca1095c,acaFilterState: Aca1095cFilterState,isAddDelete:boolean=false)
  {
        if (acaFilterState && acaFilterState.employeeIdList) {
          let filteredRecords: Aca1095cRecord[] = _.forEach(container.records, (r: Aca1095cRecord) => {
            _.forEach(acaFilterState.employeeIdList, (k) => {
              if (k.employeeId === r.employeeId) {
                r.expand = true;
                return false;
              }
            });
          });
          container.records = filteredRecords
        }
        if (acaFilterState && (acaFilterState.employeeIdList == null || acaFilterState.employeeIdList.length == 0)) { 
          let filteredRecords: Aca1095cRecord[] = _.forEach(container.records, (record: Aca1095cRecord) => {
            record.expand = acaFilterState.isExpandAll;
          });
          container.records = filteredRecords;
        }
    if(isAddDelete)
    {      
      container.actions=this.originalContainer.actions;    
    }
   
    this.originalContainer = container;
    
    this.recordsLoaded$.next(container);
    this.insightsLoaded$.next(container.insightSummary);
    let employeeFilter: EmployeeFilter = new EmployeeFilter();
    employeeFilter.employeeFilterRecord = [];
    employeeFilter.filter = [];
    if (container && container.records) {
      _.forEach(container.records, function (item) {
        let empItem: EmployeeFilterRecord = new EmployeeFilterRecord();
        empItem.id = item.employeeId;
        empItem.name = item.employeeName;
        employeeFilter.employeeFilterRecord.push(empItem);
      });

    if (acaFilterState && acaFilterState.employeeListFilter) {
        employeeFilter.filter = acaFilterState.employeeListFilter;
    }


      this.employeeFilter$.next(employeeFilter);
    }

    this.loading$.next(false);
  }
  public loadAca1095cRecords(year: number, acaFilterState: Aca1095cFilterState): void {
    if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
      return;
    }

    this.loading$.next(true);

    this.apiService.getAca1095cRecords(this.orgLevel.id, year)
      .then((container: Aca1095c) => {
        this.resetQuickPopupState(this.quickpopupState);
        this.setAca1095cRecords(container,acaFilterState);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }


  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$.pipe(
      filter((o: OrgLevel) => o && _.isFinite(o.id)))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        if (this.selectedYear) {
          this.restoreState();
        }
      });
  }


  private columnSettingsChanged(): void {
    this.subscriptions.changedColumns = this.columnManagement.columnsChanged$.pipe(filter((event: ColumnsChangedEvent) => event.group === 'Aca1095c'))
      .subscribe((event: ColumnsChangedEvent) => {
        let columnsSettings: Aca1095cSettings = new Aca1095cSettings();
        columnsSettings.columns = new Aca1095cColumnsSettings();
        columnsSettings.columns.columns = [];
        columnsSettings.columns.columns = event.columns;
        columnsSettings.columns.mapColumns();
        this.onSettingsChanged$.next(columnsSettings);
      });
  }
  public setEmployeeIdList(item: EmployeeIdList): void {

    if (this.expandedEmployeeList.indexOf(item) === -1) {
      if (item.expand) {
        this.expandedEmployeeList.push(item);
      }
      else {
        let list = _.find(this.expandedEmployeeList, { 'employeeId': item.employeeId });
        this.expandedEmployeeList = _.without(this.expandedEmployeeList, list);
      }
      this.set1095cState();
    }

  }


  public setSelectedYear(selYear: number) {
    this.selectedYear = selYear;
    this.getAcaOfferOfCoverage();
    this.restoreState();
    
  }

  public setInsightsFilter(insightsFilter: AcaInsightFilter) {
    this.insightsFilter = insightsFilter;
    this.applyInsights();
  }

  public setEmployeeFilter(filters: EmployeeFilterRecord[]) {
    this.filter = filters;
    this.set1095cState();
    this.changeEmployeeFilter(filters);
  }

  public setEmployeeGridExpandCollapse(isExpand: boolean): void {
    this.isExpandAll = isExpand;
    this.expandedEmployeeList = [];
    this.set1095cState();
  }
  public applyInsights():void
  {
    let container: Aca1095c = _.clone(this.originalContainer);
    let records: Aca1095cRecord[] = container.records;
    if (this.insightsFilter) {
      switch (this.insightsFilter.filter) {
        case 'FT_Enrolled_FullYear': {
          records = _.filter(records, (item: Aca1095cRecord) => {
            if(item.isFullTimeEnrolledFullYear)
            {
              return true;
            }
          });
          break;
        }
        case 'FT_Enrolled_PartYear': {
          records = _.filter(records, (item: Aca1095cRecord) => {
            if(item.isFullTimeEnrolledPartYear)
            {
              return true;
            }
          });
          break;
        }
        case 'FT_NotEnrolled': {
          records = _.filter(records, (item: Aca1095cRecord) => {
            if(item.isFullTimeNotEnrolled)
            {
              return true;
            }
          });
          break;
        }
        case 'PT_Enrolled': {
         
          records = _.filter(records, (item: Aca1095cRecord) => {
            if(item.isPartTimeEnrolled)
            {
              return true;
            }
          });
          break;
        }
        case 'NotConfirmed': {
          records = _.filter(records, (item: Aca1095cRecord) => {
            if(item.isNotConfirmed)
            {
              return true;
            }
          });
          
          break;
        }      
        default: {
          
          break;
        }       
      }
      
    }
    container.records = records;
    this.recordsLoaded$.next(container);
    let employeeFilter: EmployeeFilter = new EmployeeFilter();
    employeeFilter.employeeFilterRecord = [];
    employeeFilter.filter = [];
    this.employeeFilterRecords$.next(employeeFilter.employeeFilterRecord);
    if (container && container.records) {
      _.forEach(container.records, function (item) {
        let empItem: EmployeeFilterRecord = new EmployeeFilterRecord();
        empItem.id = item.employeeId;
        empItem.name = item.employeeName;
        employeeFilter.employeeFilterRecord.push(empItem);
      });


      employeeFilter.filter = [];

      this.filter = [];
      this.set1095cState();

      this.employeeFilter$.next(employeeFilter);
    }
  }
  
  public set1095cState(): void {
    if (!this.selectedYear) {
      return;
    }
    let state: IAca1095cState = _.clone(initialAca1095cState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }
    let acaState: Aca1095cFilterState = new Aca1095cFilterState();
    acaState.year = this.selectedYear;
    acaState.orgLevelId = this.orgLevel.id;
    acaState.employeeListFilter = this.filter;
    if (this.expandedEmployeeList && this.expandedEmployeeList.length > 0) {
      acaState.employeeIdList = this.expandedEmployeeList;
    }    
    if (this.expandedEmployeeList && this.expandedEmployeeList.length == 0) {
        acaState.isExpandAll = this.isExpandAll;
    }
    if (state && state.aca1095cStateList && state.aca1095cStateList.length > 0) {
      let index = _.findIndex(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
      if (index >= 0) {
        let item = _.find(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
        state.aca1095cStateList = _.without(state.aca1095cStateList, item);
        state.aca1095cStateList.push(acaState);
      }
      else {
        state.aca1095cStateList.push(acaState);
      }
    }
    else {
      state.aca1095cStateList = [];
      state.aca1095cStateList = [acaState];
    }

    this.saveState(state);

  }
  public saveState(state: IAca1095cState): void {
    this.stateManagement.setControlState(this.stateKey, {
      value: state
    }, StateResetTypes.None);
  }
  public restoreState(): void {
    if (!this.selectedYear) {
      return;
    }

    let state: IAca1095cState = _.clone(initialAca1095cState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    if (state && state.aca1095cStateList) {
      if (_.findIndex(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
        this.acaFilterState = _.find(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
      }
      else {
        this.acaFilterState = null;
      }
    }
    else {
      this.acaFilterState = null;
    }
    if(this.acaFilterState)
    {
      this.isExpandAll=this.acaFilterState.isExpandAll;
    }
    this.loadAca1095cRecords(this.selectedYear, this.acaFilterState);

  }

  
  
}

