<slx-spinner [show]="state.isLoading">

    <form novalidate autocomplete="none" #form="ngForm">

        <div class="container left-side" *ngIf="userProfile">

            <div class="row">

                <div class="col-sm-12 col-md-3">

                    <div class="row">
                        <div class="col-sm-12">
                            <slx-input-decorator>
                                <input slx-input type="text" required="true" filled maxlength="50" name="name" placeholder="Name" [(ngModel)]="tempModel.name"
                                    [readonly]="!state.isEditMode" tabindex="1">
                                <span errorMessage for="required"></span>
                                <span errorMessage for="filled"></span>
                            </slx-input-decorator>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="slx-form-group slx-readonly" *ngIf="!state.isEditMode">
                          <label class="slx-label">Employee Record Link</label>
                          <div>
                            <a href="#" [employeeSectionLink]="tempModel.employee?.id" *ngIf="tempModel.employee">
                              <span>{{ tempModel.employee?.name }}</span>
                            </a>
                            <span *ngIf="!tempModel.employee">No Employee Record Link</span>
                          </div>
                        </div>

                        <div *ngIf="state.isEditMode" class="slx-form-group slx-readonly">
                            <slx-spinner [show]="isLoading">
                                <label class="slx-label">Employee Record Link</label>

                                <slx-autocomplete #employeesModel="ngModel"
                                    class="slx-wide"
                                    [options]="employees"
                                    [(ngModel)]="tempModel.employee"
                                    [strictSearch]="false"
                                    [virtual]="virtual"
                                    rightIcon="fas fa-search"
                                    name="employees">
                                    <ng-template slxAutocompleteItem let-item="item">
                                        <span class="employee-row">
                                            <span class="employee-name">{{item.name}}</span>
                                            <span class="info-line">Organization: {{item.organizationName}}</span>
                                            <span class="info-line">Payroll No: {{item.payrollNumber}}</span>
                                        </span>
                                    </ng-template>
                                </slx-autocomplete>
                            </slx-spinner>
                        </div>
                      </div>
                    </div>

                </div>

                <div class="col-sm-12 col-md-3">

                    <div class="row">
                        <div class="col-sm-12">
                            <slx-input-decorator>
                                <slx-email-input slx-input slx-email required="true" filled maxlength="50" slxMaxLength="50"
                                                 placeholder="Email" [(ngModel)]="tempModel.email" name="email"
                                                 [readonly]="!state.isEditMode" [tabindex]="2"></slx-email-input>
                                <span errorMessage for="required"></span>
                                <span errorMessage for="filled"></span>
                                <span errorMessage for="email"></span>
                                <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
                            </slx-input-decorator>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-8 nested-left-col">
                                <slx-input-decorator>
                                    <slx-phone-input slx-input maxlength="50" name="workphone" placeholder="Work Phone"
                                    [(ngModel)]="tempModel.workPhone" [readonly]="!state.isEditMode"  [tabindex]="5">
                                </slx-phone-input>
                                </slx-input-decorator>
                            </div>
                            <div class="col-sm-4 nested-left-col nested-right-col">
                                <slx-input-decorator>
                                    <input slx-input type="text" pattern="\d*" maxlength="10" minlength="1" name="phoneext" placeholder="Ext"
                                    [(ngModel)]="tempModel.ext" [readonly]="!state.isEditMode"  tabindex="6">
                                    <span errorMessage for="pattern">1 to 10 digits code</span>
                                </slx-input-decorator>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <slx-input-decorator>
                                <slx-phone-input slx-input maxlength="50" name="fax" placeholder="Fax"
                                [(ngModel)]="tempModel.fax" [readonly]="!state.isEditMode"  [tabindex]="8"></slx-phone-input>
                            </slx-input-decorator>
                        </div>
                    </div>

                </div>

                <div class="col-sm-12 col-md-3 auth-controls">

                    <!-- surpress form autocomplete. chrome ignores form autocomplete attribute -->
                    <input id="fake-login" name="fake-login" class="hidden-field">
                    <input id="fake-password" name="fake-password" type="password" class="hidden-field" autocomplete="new-password">

                    <div class="row">

                        <div class="col-sm-12" *ngIf="state.isEditMode && userProfile?.isNew">
                            <slx-input-decorator>
                                <input slx-input type="text" required="true" filled maxlength="50" name="login" placeholder="Login ID" [(ngModel)]="tempModel.login"
                                #ngModel="ngModel" [model]="ngModel"
                                [server]="{validationName: userNameValidation, parameters: [tempModel.login], validationAdapter: userValidatorAdapter}"
                                    [readonly]="false" tabindex="3">
                                <span errorMessage for="required"></span>
                                <span errorMessage for="filled"></span>
                                <span errorMessage [for]="userNameValidation">msg from server</span>
                            </slx-input-decorator>
                        </div>

                        <div class="col-sm-12" *ngIf="!state.isEditMode || !userProfile?.isNew">
                            <slx-input-decorator>
                                <input slx-input type="text" required="true" filled maxlength="50" name="login" placeholder="Login ID" [(ngModel)]="tempModel.login"
                                    [readonly]="true" tabindex="3">
                            </slx-input-decorator>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="password-container">
                                <slx-input-decorator class="control">
                                    <input slx-input type="password" required="true" name="password" placeholder="Password" [(ngModel)]="tempModel.password" tabindex="7"
                                    [prohibitedValues]="tempModel.login" [readonly]="!state.isEditMode"
                                    #ngModel="ngModel" [model]="ngModel"
                                    [server]="{validationName: passwordValidation, parameters: [tempModel.password], validationAdapter: passwordValidator}"
                                    >
                                    <span errorMessage for="required">Value is reqired</span>
                                    <span errorMessage for="prohibitedValues">Password and login should not be same</span>
                                    <span errorMessage [for]="passwordValidation">Password must comply with the requirements</span>
                                </slx-input-decorator>
                                <slx-input-decorator class="control" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                                        <slx-checkbox-input slx-input
                                            name="resetPasswordNextLogin" fieldName="resetPasswordNextLogin"
                                            caption="Reset Password on next login"
                                            [(ngModel)]="tempModel.resetPasswordOnNextLogin"
                                            [readonly]="!state.isEditMode"
                                            >
                                        </slx-checkbox-input>
                                </slx-input-decorator>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-12 textarea-resize-none">
                    <slx-input-decorator className="slx-form-group slx-form-expand">
                        <textarea  slx-input name="address" placeholder="Work Address" maxlength="100"
                                  [(ngModel)]="tempModel.workAddress" [readonly]="!state.isEditMode"  tabindex="9"></textarea>
                    </slx-input-decorator>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 textarea-resize-none">
                    <slx-input-decorator className="slx-form-group slx-form-expand ">
                        <textarea  slx-input name="comments" placeholder="Comments" maxlength="200"
                                  [(ngModel)]="tempModel.comments" [readonly]="!state.isEditMode"  tabindex="10"></textarea>
                    </slx-input-decorator>
                </div>
            </div>
        </div>

    </form>
</slx-spinner>
